export const environment = {
  production: false,
  stage: 'integration',
  companyId: 'krc4PsvgVuBKYck4mxen',
  amChartsKey: 'AM5C-5078-8816-8831-9926',
  firebaseConfig: {
    apiKey: "AIzaSyC08Ki2u-bSDdv4pUaVVO7WMZswi4tB57s",
    authDomain: "eule-int.firebaseapp.com",
    projectId: "eule-int",
    storageBucket: "eule-int.appspot.com",
    messagingSenderId: "845792988314",
    appId: "1:845792988314:web:c418ea52c6d28962996323",
    measurementId: "G-GHRMWYJMWY"
  }
};
